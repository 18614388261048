.serial-num{
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #3e4a56;
    width: 1.5em;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-weight: 500;
    margin-bottom: 5px;
}
.sub-head{
    margin: 0px;
    text-align: start;
    margin-top: 1em;
}
.plan-selection-container{
    margin-left: 1em;
    margin-right: 1em;
}
.plan-selection-subhead{
    font-family: Maxis;
  font-size: 20px;
  font-weight: bold;
  color: #3e4a56;
  margin-left: 0.5em;
}
.sub-small-text{
    font-family: Maxis;
  font-size: 13px;
  color: #8e8f98;
  font-weight: 600;
  margin: 0px;
  text-align: start;
  margin-left: 3em;
}

/* card styles */
.card-container{
    padding: 5px 10px 10px;
    margin-top: 1em;
    border-radius: 14px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.17);
    text-align: start;
    position: relative;
}
.card-head{
    font-family: Maxis;
    font-size: 14px;
    font-weight: bold;
    color: #3e4a56;  
    display: inline-block;
    /* margin-bottom: 0.5em; */
    margin: 0.7em;
}
.card-bill-info{
    font-family: Maxis;
    font-size: 11px;
    color: #3e4a56;
    margin: 0px;
}
.card-month-amount{
    font-family: Maxis;
    font-size: 13px;
    font-weight: bold;
    color: #3e4a56;
    margin: 0px;
    margin-top: 0.5em;
}
.card-amount{
    font-family: Maxis;
    font-size: 24px;
    font-weight: bold;
    text-align: right;
  color: #3e4a56;
}
.top-card-amount{
    font-family: Maxis;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  color: #3e4a56;
}
.zero-margin{
    margin: 0px;
}
.zero-padding{
    padding: 0px;
}
.top-sub-text{
    font-size: small;
    font-weight: 500;
    color: #3e4a56;
}
.top-sub-text{
    font-size: small;
    font-weight: 500;
    color: #3e4a56;
}
.sub-text-card{
    font-size: 12px;
    color: #3e4a56;
    font-weight: bold;
    font-family: Maxis;
}
.recomended-tag{
    position:absolute;
    top: 0px;
    right: 1.5em;
    background-color: #557fff;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border-top-right-radius: 14px; */
    padding: 3px 12px 6px;
    font-family: Maxis;
  font-size: 12px;
}

/* card styles end */


.bottom-card-container{
    border-radius: 14px;
    background-color: #c9dce5; 
    /* margin-top: 2em; */
    text-align: start;
    padding: 10px 20px;
}
.bottom-card-head{
    font-family: Maxis;
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.bottom-card-text{
    font-family: Maxis;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: normal;
  color: #3e4a56;
}

.select-acc-head{
    font-family: Maxis;
  font-size: 14px;
  font-weight: bold;
  color: #3e4a56;
}
.acc-no-text{
    font-family: Maxis;
    font-size: 18px;
    color: #030303;
    line-height: 1.22;   
    margin: 0px; 
}
.acc-ph-num{
    font-family: Maxis;
  font-size: 14px;
  color: #8e8f98;
  margin-top: 0px; 
}
.avg-bill-text{
    font-family: Maxis;
    font-size: 12px;
    color: #3e4a56;
    margin: 0px;
}
.edit-icon{
    color: #3e4a56;
}
.content{
    color: #3e4a56;
    font-family: Maxis;
    margin: 0px;
    margin-top: 0.5em;

}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .Mui-checked{
color: #3e4a56;
}

.bill-amount{
    position: absolute;
    bottom: 2px;
    right: 1em;
    
}

.MuiFormControlLabel-root .MuiSwitch-root{
    width: 71px;
    height: 39px;
}
.MuiSwitch-root .PrivateSwitchBase-input .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
}
.css-wmp7hx-MuiSwitch-root .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
right: 8px;
}
.css-mhc70k-MuiGrid-root{
    margin-left: -10px;
}