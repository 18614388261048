    .Logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    img.Maxis-insure-logo {
    width: 208px;
    height: 37px;
    margin: 30px 83px 20px 84px;
    object-fit: contain;
  }

  .Powered-by {
    width: auto;
    max-width: 600px;
    height: 15px;
    margin: 20px 102px 8px 103px;
    font-family: Maxis;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8f98;
  }

  img.AIA-logo {
    width: 54px;
    height: 54px;
    margin: 8px 160px 30px 161px;
    object-fit: contain;
  }

  .Divider {
    width: auto;
    height: 0.5px;
    background-color: #dcdcdc;
    margin: 0 1em 0;
  }

  .Maxis-Insure-desc1 {
   text-align: center;
   margin: 30px 2em 20px;
   width: auto;
   height: 11em;
   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   color: #8d8e97;
  }

  .Maxis-Insure-desc2 .tnc {
    font-weight: 600;
    color: #557fff;
  }
  .Maxis-Insure-desc2 {
    width: auto;
    height: 30px;
    margin: 22px 2em 4.5em;
    font-family: Maxis;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8d8e97;
  }
  
  .Allow-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.5em 1em;
    height: auto;
  }

  .click-continue{
    /* height: 60px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1em;
  }

  .AIA-copysheet{
    height: 105px;
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .checkbox {
    width: 24px;
    height: 24px;
    margin: 0 8px 6px 0;
    object-fit: contain;
  }

  .Allow-desc {
    width: auto;
    /* height: 30px; */
    margin: 0;
    font-family: Maxis;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8f98;
    text-align: left;
  }

  .Continue-btn{
    margin: 1.5em 0;
    width:100%;
    padding: 0;
  }

  .continue-btn-text{
    margin: 1em 0;
    width: 72px;
  height: 17px;
  font-family: Maxis;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  }
  .link-text{
    color:#557fff;
    font-weight: bold;
  }

  .heading-top{
    margin: 30px 111px 16px;
    font-family: Maxis;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e4a56;
  }
