#header-top{
  margin-top: 5px;
  font-family: "Maxis";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e4a56;
}
#qtn-text{
    font-family: "Maxis";
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
  text-align: '-webkit-left';
}
#ans-text{
    font-family: Maxis;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #8d8e97;
  text-align: start;
  text-align: justify;
}
/* #table-text,th,tr,td{
  border: 1px solid black;
  border-collapse: collapse;
  padding:'6px';

} */

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}