:root {
    --sunflower-yellow: #e3e400;
    --darkish-green: #388e3c;
    --warm-grey: #737373;
    --bright-lime: #8ce600;
    --sick-green-80: rgba(166, 206, 57, 0.8);
    --black: #000;
    --brownish-grey: #636363;
    --light-blue-grey: #daf2f2;
    --white: #fff;
    --blue-green: #115e67;
    --maxis-green: #40c706;
    --black: #000;
  }

.-BackButton{
    margin: 0px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 21px;
  }

  .-Label {
    width: 35px;
    height: 22px;
    margin: 11px 15px 11px 4px;
    font-family:Maxis ;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #000000;
  }

.number{

}
.number-row{
  background-color: #3e4a56;
    color: white;
    padding: 4px 10px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: bold;
    font-family: 'MAXIS';
}
.number-row2{
  background-color: #3e4a56;
    color: white;
    padding: 4px 8px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: bold;
    font-family: 'MAXIS';
}

  .-Description{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 15px;
  }

  .desc-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .number-icon{
    margin-left: 1.2em;
  }

  .desc-1{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 9px 0px 15px;
}



.Account-selection {
    width: 315px;
    height: 24px;
    margin: 13px 9px 4px 10px;
    font-family: Maxis;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3e4a56;
  }

  .Select-an-account-to {
    width: 309px;
    height: 16px;
    margin: 4px 15px 15px 10px;
    font-family: Maxis;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8f98;
    text-align:left;
    justify-content: left;
  }

  .Mask {
    width: auto;
    height: auto;
    margin: 1em 1em;
    border-radius: 14px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.17);
    background-color: var(--white);
  }

/* //checkbox */
  input.checkbox {
    width: 24px;
    height: 24px;
    margin: 8px 10px 20px 15px;
    object-fit: contain;
  }

  .avg-bill{
    background-color: red;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .continue-btn{
    background-color: #40c706;
    width: 92%;
    margin: 0 1em;
    border: none;
    border-radius: 1em;
    color: "white";
    font-size: 1em;
    font-weight: bold;
    height: 50px;
    object-fit: contain;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.continue-btn-text{
    color: white;
    font-family: Maxis;
}

.accNum-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-grow: 1;
    max-width: 100%;
    flex-wrap: wrap;
}

.price-row{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-content: stretch;
    flex-flow: column;
    flex-grow: 1;
}

.checklist-column{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0.7em 0;
    margin: 0 1em;
}

.checklist-column:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}

.checklist-column-disable{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0.7em 0;
  margin: 0 1em;
}
.checklist-column-disable:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}

.checkbox-column{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0.7em 0;
  margin: 0 1em;
}

.checkbox-column.border-bottom{
  border-bottom: 1px solid #dcdcdc;
}

.accNum{
    width: auto;
    height: 22px;
    margin: 0px 0.8em;
    font-family: Maxis;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #030303;
    max-width: 182px;
}

.planName{
  width: auto;
  height: auto;
  margin: 0px 0.8em;
  font-family: Maxis;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color:#8e8f98;
  /* max-width: 182px; */
  text-align: initial;
}

.telNum{
  width: 100%;
  height: auto;
  margin: 0px 6px 6px 13px;
  font-family: Maxis;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #8e8f98;
  text-align:left;
  max-width: 100%;
}

.avg-bill-text{
  width: 130px;
  height: 13px;
  margin: 0 15px 0 0;
  font-family: Maxis;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8d8e97;
}

.avg-bill-price{
    width: 89px;
  height: 16px;
  margin: 8px 15px 15px 10px;
  font-family: Maxis;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8d8e97;
}

.per-month{
    font-size: 12px;
}

.-border{
   margin: 0 1em 8px;
  }

.bottom-logo{
    margin: 83px 0 15px 15px;
}

.Divider {
  width: auto;
  height: 0.5px;
  background-color: black;
  margin: 0 1em 0;
}

input[type='radio']:after {
  width: 24px;
  height: 24px;
  content: '';
  display: inline-block;
  visibility: visible;
  background:url(../../images/select-off.svg) no-repeat;
}

input[type='radio']:checked:after {
  width: 24px;
  height: 24px;
  content: '';
  display: inline-block;
  visibility: visible;
  background:url(../../images/selector-on.svg) no-repeat;
}

.radio-custom{
  background:url(../../images/selector-on.svg) no-repeat;
  /* cursor: none; */
  width: 24px;
  height: 24px;
  margin: 0;
  border: 2px solid white;
  visibility: hidden;
}

.radio-custom-disable{
  background:url(../../images/disable-checkbox.svg) no-repeat;
  /* cursor: none; */
  width: 24px;
  height: 24px;
  margin: 0;
  border: 2px solid white;
  /* visibility: hidden; */
  display: inline-block;
}
input[type='checkbox']:after {
  width: 24px;
  height: 24px;
  content: '';
  display: inline-block;
  visibility: visible;
  background:url(../../images/select-off.svg) no-repeat;
}

input[type='checkbox']:checked:after {
  width: 24px;
  height: 24px;
  content: '';
  display: inline-block;
  visibility: visible;
  background:url(../../images/select-on.svg) no-repeat;
}

.protected-btn-container{
  margin: 2px;
}
#protected-btn{
  font-size: x-small;
  background-color: #557fff;
  border-radius: 8px;

}
