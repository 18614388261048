.container{
    background-color: #115e67;
    height: 100vh;
}
.bottom-container{
    background-color: white;
    height: 65%;
    position: relative;
    top: 35%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.top-container{
    position: absolute;
    height: 35%;
    width: -webkit-fill-available;
}
.middle-card{
    height: 4.3em;
    width:21em;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
}
.middle-card-parent{
    display: flex;
    justify-content: center;
    bottom: 2em;
    position: relative;
}
.top-card-parent{
    display: flex;
    justify-content: center;
    top: 5%;
    position: relative;

}
.top-card{
    height: 4.3em;
    width:20em;
    background-color: #fff;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 9em;
}
.badge-bar{
    margin-top: 2%;
}
.top-text{
    font-size: 14px;
    color: white;
}
.top-text-bold{
    font-size: 14px;
    font-weight: 600;
    color: white;
}
.default-container{
    width: 10%;
    height: 53%;
    position: relative;
    top: 47%;
    left: 91%;
    border: none;
}
.icon-arrow-btn{
    position: relative;
    bottom: 50%;
    left: 42%;
    height: 1.2em;
    width: 1.2em;
}
.card-content{
    position: relative;
    height: -webkit-fill-available;
    bottom: 65%;
}
.image-container{
    border: 2px solid;
}
.infinite-img{
    border-radius: 50%;
    padding: 1em;
    border:3px solid #40c706;
}
.card-head-text{
    color: #8d8e97;
    opacity: 0.8;
    font-size: 14px;
  font-weight: 600;
  text-align: start;
  margin: 0 0 4px;
}
.mobile-text{
    color: #3e4a56;
    font-size: 17px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.23px;
  margin: 0px 0px 0 0;
  text-align: start;
}
.card-btn-text{
    font-size: smaller;
  font-weight: 400;
}
.manage-wifi-btn{
    width: fit-content;
}
.middle-card-flex{
    flex-direction: row;
}
.middle-card-column{
    display: inline-block;
    margin: 8px;
}
.middle-card-column-text{
    margin: 0px;
    font-weight: 600;
    font-size: 0.75em;
    letter-spacing: 0.17px;
    color: #3e4a56;
}










/* //below 800 height */
@media only screen and (max-height: 800px) {
    .bottom-container{
    top: 40%;
    }
  }

  /* //below 700 height */
@media only screen and (max-height: 700px) {
    .bottom-container{
    top: 48%;
    }
  }
  /* //below 600 height */
@media only screen and (max-height: 600px) {
    .bottom-container{
    top: 55%;
    }
  }







/* //   landing PAge */

.logo-container{
    background-color: #3e4a56;
    height: 13.25em;
    padding: 79px 161px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.landingPage-logo{
    height: 4.5em;
    width: 4.5em;
}
.bill-protextion-head{
  font-size: 20px;
  font-weight: bold;
  text-align: start;
  font-family: Maxis;
  color: #3e4a56;
  margin-top: 20px;
  margin-bottom: 8px;
  font-family: Maxis;
}
.bill-protextion-text{
    color: #000000;
    text-align: start;
    font-size: 14px;
  font-weight: 600;
  font-family: Maxis;
}
.coverage-plan{
    color: #3e4a56;
    font-size: 20px;
    font-weight: bold;
    text-align: start;
    font-family: Maxis;
}
.coverage-plan-container{
    /* height: 12em; */
    background-color: #fcf7e8;
    margin-bottom: 2em;
    border-radius: 1.2em;
}
.left-tab{
    height: 50%;
    background: gray;
    width: 40%;
    border-top-left-radius: 1.2em;
}
.small-tab{
    background: gray;
    margin-right: 1px;
    font-family: Maxis;
    padding: 0px 2px;
}
.small-tab{
    background: gray;
    margin-right: 1px;
    font-family: Maxis;
}
.small-tab-top{
    background: #3e4a56;
    margin-right: 1px;
    font-family: Maxis;
    padding: 0px 2px;
}
.top-text{
    color:"#c9dce5"
}
.top-tab{
    display: flex;
  flex-wrap: nowrap;
  /* height: 6em; */
  margin-top: 1px;
}
.top-tab-top{
    display: flex;
  flex-wrap: nowrap;
  height: 4em;
}
.bottom-tab{
    display: flex;
  flex-wrap: nowrap;
  height: 6em;
  margin-top: 1px;
}
.top-left-tab{
    width: 40%;
    background: #3e4a56;
    border-top-left-radius: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-left-tab-data{
    width: 40%;
    background: #3e4a56;
    /* border-top-left-radius: 1.2em; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: start;
}
.small-tab{
    width: 20%;
    background: #c4dad5;
    font-size: 15px;
  font-weight: bold; 
  color: #3e4a56;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-tab-top{
    width: 20%;
    background: #3e4a56;
    font-size: 15px;
  font-weight: bold; 
  color: #c9dce5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-tab-top-first{
    width: 20%;
    background: #3e4a56;
    font-size: 15px;
  font-weight: bold; 
  color: #c9dce5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;
  padding: 0px 2px;
}
.bottom-left-tab{
    width: 40%;
    background: #3e4a56;
    border-bottom-left-radius: 1.2em;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: start;
}
.small-tab-bottom-last{
    width: 20%;
border-bottom-right-radius: 1.2em;  
background: #c4dad5;
font-size: 15px;
font-weight: bold; 
color: #3e4a56;
display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
}
.small-tab-top-last{
    width: 20%;
border-top-right-radius: 1.2em; 
background: #3e4a56;
font-size: 15px;
font-weight: bold; 
color: #c9dce5; 
display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
}
.small-tab-top-last-data{
    width: 20%;
/* border-top-right-radius: 1.2em;  */
background: #c4dad5;
font-size: 15px;
font-weight: bold; 
color: #3e4a56; 
display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
}
.left-tab-text{
    color:#c9dce5;
    font-size: 15px;
  font-weight: bold;
font-family: Maxis;
line-height: 1.2;
margin-top: 4px;
margin-bottom: 4px;
/* padding: 0px 10px; */
}
.left-tab-text-top{
    color:#c9dce5;
    font-size: 15px;
  font-weight: bold;
font-family: Maxis;
}
.buy-now-btn{
    background-color: #40c706;
    padding:2em;
    width: 100%;
    border: none;
    border-radius: 1em;
    color: "white";
    font-size: 1em;
    font-weight: bold;
    height: 4.5em;
}
.buy-now-btn-text{
    color: white;
    font-family: Maxis;
    font-size: 14px;
    font-weight: bold;
}
.margin-container{
    margin: 1em;
}
.need-help-text{
    color: #3e4a56;
    font-size: 12px;
  font-weight: 600;
  text-align: start;
  margin-top: 2em;
  font-family: Maxis;
}
.bottom-accordian-container{
height: 4em;
display: grid;
}

.manage-policy-head{
    color: #3e4a56;
    font-size: 20px;
    font-weight: bold;
    text-align: start;
    font-family: Maxis;
}
.mypolicy-tab{
    height: 5em;
    background-color: #c4dad5; 
    border-radius: 1em;
    margin-top: 1em;
}
.faq-tab{
    height: 4em;
    background-color: white; 
    border-radius: 1em;
    margin-top: 1em;
    /* box-shadow: 1px 2px #888888; */
}
.policy-head{
    font-size: 18px;
    font-weight: bold;
    text-align: start;
    margin: 0px;
    margin-left: 1em;
    padding-top: 0.5em;
    font-family: Maxis;
}
.faq-head{
    font-size: 18px;
    font-weight: bold;
    text-align: start;
    margin: 0px;
    margin-left: 1em;
    padding-top: 1em;
    font-family: Maxis;
    color: black;
}
.policy-text{
    text-align: start;
    margin: 0px;
    margin-left: 1em;
    font-size: 14px;
    font-weight: 600;
    color: #3e4a56;
    padding-top: 0.5em;
    font-family: Maxis;
}
.icon-arrow-btn-policy{
    bottom: 1em;
    left: 43%;
    width: 26px;
  height: 26px;
}
.icon-arrow-home{
    /* position: absolute;
    bottom: 50%; */
}
.margin-top{
    margin-top: 1em;
}
.aia-logo{
    margin-left: 5px;
}
.powered-by-text{
    font-family: Maxis;
    font-size: 12px;
    font-weight: 600;
    color: #8d8e97;
    position: relative;
    bottom: 5px;
}
.bottom-logo{
    margin-top: 2em;
    text-align: start;
}

a{
    text-decoration: none;
}

.left-tab-sub-text{
    /* width: 103px;
    height: 15px;
    margin: 4px 0 31px; */
    font-family: Maxis;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c9dce5;
}

.contact-us-tab{
    text-align: initial;
    padding: 5px 10px;
}
.contact-us-tab p{
    margin: 0px;
    font-family: Maxis;
}
.contact-us-tab h3{
    font-family: Maxis;
    margin: 5px 0px;
}
.row-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.container-inner{
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: baseline;
}
.contactUsValues{
    font-family: Maxis;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #8d8e97; 
}
.contactUsKeys{
    font-family: Maxis;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #000;
}
.underwritten{
    font-family: Maxis;
    font-size: 12px;
    line-height: 0.3;
    color: blue;
}