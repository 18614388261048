.dialog-box {
  border-radius: 1em;
}
.MuiDialog-container .MuiDialog-paperWidthSm {
  border-radius: 1em;
}
#alert-dialog-title {
  font-family: Maxis;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
#alert-dialog-description {
  margin: 10px 0 16px;
  font-family: Maxis;
  font-size: 14px;
  color: black;
}
#ok-btn {
  background-color: #40c706;
  /* padding:1em; */
  /* width: 100%; */
  border: none;
  border-radius: 1em;
  color: "white";
  font-size: 1em;
  font-weight: bold;
  height: 3em;
  margin-left: 1.5em;
  margin-right: 1.5em;
}
#cancel-btn {
  background-color: white;
  border: none;
  border-radius: 1em;
  color: "#40c706";
  font-size: 1em;
  font-weight: bold;
  height: 3em;
  margin: 1.5em;
}
.textLayer {
  opacity: 0;
}
.react-pdf__Document .react-pdf__Page .textLayer {
  display: none;
}
.cancel-text {
  font-family: Maxis;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3e4a56;
}

/* model styles */

.footer .container,
.footer .card-container {
  display: none;
}

.container {
  background-color: white;
}
.css-1wnsr1i {
  overflow: scroll;
}
.navbar-sub {
  display: none;
}
.react-pdf__Page .react-pdf__Page__canvas {
  width: -webkit-fill-available;
  overflow: scroll;
}

/* pdf vierer */
.next-btn {
  margin-left: 2px;
}
/* end */

.parent-text {
  text-align: justify;
  margin: 5px;
  color: #363e3f;
  font-family: "AIABody", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 17px;
  line-height: 22px;
}
.sub-head {
  font-weight: bold;
  text-align: left;
  color: #363e3f;
  font-family: "AIABody", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 19px;
  line-height: 22px;
}
.model-header {
  text-align: center;
  color: #363e3f;
  font-family: "AIATitle", "Calibri", sans-serif;
  font-weight: inherit;
  text-transform: uppercase;
  /* font-size: 48px;
    line-height: 44px; */
}

canvas {
    width: 100% !important;
   /* Adding !important will give this rule more precedence over inline style */
}
.pdf-container {
  /* Set desired styles for the PDF container */
}

.pdf-page {
  margin-bottom: 10px; /* Add desired spacing between pages */
}

.page-break {
  page-break-after: always; /* Create a page break after each page */
  border-bottom: 2px solid gray; /* Example border styling for the page break */
}
